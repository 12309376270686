.contact_wrapper {
  svg {
    font-size: 1em;
    vertical-align: middle;
    margin-right: 5px;
  }

  .contact-info {
    padding-top: 20px;

    .contact-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 10px;
      }

      p {
        margin: 0;
        font-size: 1.2rem;
        font-family: 'DomaineDispNar-Medium', sans-serif;
      }
    }
  }

  label {
    font-size: 1.2rem;
    font-family: 'DomaineDispNar-Medium', sans-serif;
  }
}
